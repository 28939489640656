import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import axios from 'axios'
import VueVideoPlayer from 'vue-video-player'

import router from './router'
import store from './store'
import App from './App.vue'

// Auth Components
import { setHeaderToken } from './utils/auth'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/clipboard'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import './registerServiceWorker'

import 'video.js/dist/video-js.css'

// import VueSweetalert2 from 'vue-sweetalert2'
// import 'sweetalert2/dist/sweetalert2.min.css';

// Vue.use(VueSweetalert2)

import { EmbedPlugin } from 'bootstrap-vue'
Vue.use(EmbedPlugin)

Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(require('vue-moment'));

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
// Auth Header
const token = localStorage.getItem('token')

if (token) {
  setHeaderToken(token)
}

// Live URL
axios.defaults.baseURL = 'https://hrs.baliroyalhospital.co.id/api/'
// Dev URL
// axios.defaults.baseURL = 'https://api-hrs.baliroyalhospital.co.id/bros_hrs_dev/public/api/'
// local dev URL
// axios.defaults.baseURL = 'http://192.168.1.99/bros_hrs_dev/public/api/'

Vue.config.productionTip = false

store.dispatch('auth/get_user', token)
  .then(() => {
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  }).catch(error => {
    console.error(error)
  })
