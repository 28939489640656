import axios from 'axios'
import { setHeaderToken, removeHeaderToken } from '@/utils/auth'

export default {
  namespaced: true,
  state: {
    userData: null,
    isLoggedIn: false,
  },
  mutations: {
    set_user(state, data) {
      state.userData = data
      state.isLoggedIn = true
    },
    reset_user(state) {
      state.userData = null
      state.isLoggedIn = false
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn
    },
    userData(state) {
      return state.userData
    },
  },
  actions: {
    login({ dispatch, commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('login', data)
          .then(response => {
            const { token } = response.data
            const userData = response.data.user
            localStorage.setItem('token', token)
            localStorage.setItem('userData', JSON.stringify(userData))
            setHeaderToken(token)
            dispatch('get_user')
            resolve(response)
          })
          .catch(err => {
            commit('reset_user')
            localStorage.removeItem('token')
            localStorage.removeItem('userData')
            reject(err)
          })
      })
    },
    async get_user({ commit }) {
      if (!localStorage.getItem('token') && !localStorage.getItem('userData')) {
        return
      }
      try {
        const empCode = JSON.parse(localStorage.getItem('userData'))
        //console.log(empCode);
        axios.post('employee/detil', {
          "employee_code": empCode.employee_code
        })
        .then(response => {
          //console.log(response);
          commit('set_user', response.data.data)
        })
      } catch (error) {
        commit('reset_user')
        removeHeaderToken()
        localStorage.removeItem('token')
        localStorage.removeItem('userData')
        return error
      }
    },
    logout({ commit }) {
      return new Promise(resolve => {
        commit('reset_user')
        localStorage.removeItem('token')
        localStorage.removeItem('userData')
        removeHeaderToken()
        resolve()
      })
    },
    updatePic({ commit }) {
      return new Promise (resolve => {
        
      })
    }
  },
}
