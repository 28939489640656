import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        resource: 'Home',
        requiresAuth: true,
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/cuti',
    //   name: 'cuti',
    //   component: () => import('@/views/cuti/Cuti.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     pageTitle: 'Cuti',
    //     breadcrumb: [
    //       {
    //         text: 'Cuti',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/cuticard',
      name: 'cuticard',
      component: () => import('@/views/cuti/CutiCard.vue'),
      meta: {
        resource: 'Cuti',
        action:'read',
        requiresAuth: true,
        pageTitle: 'Cuti',
        breadcrumb: [
          {
            text: 'Cuti',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/updatecuti/',
    //   params: ':_id',
    //   name: 'updatecuti',
    //   component: () => import('@/views/cuti/UpdateCuti.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     pageTitle: 'Cuti',
    //     breadcrumb: [
    //       {
    //         text: 'Update Cuti',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '/scheduleExchange',
      name: 'scheduleExchange',
      component: () => import('@/views/schedule/ScheduleExchange.vue'),
      meta: {
        resource: 'Exchange',
        action:'read',
        requiresAuth: true,
        pageTitle: 'Tukar Dinas',
        breadcrumb: [
          {
            text: 'Tukar Dinas',
            active: true,
          },
        ],
      },
    },
    {
      path: '/schedule',
      name: 'schedule',
      component: () => import('@/views/schedule/Schedule.vue'),
      meta: {
        resource: 'Schedule',
        action:'read',
        requiresAuth: true,
        pageTitle: 'Schedule',
        breadcrumb: [
          {
            text: 'Schedule',
            active: true,
          },
        ],
      },
    },
    {
      path: '/SickCard',
      name: 'SickCard',
      component: () => import('@/views/sickLeaves/SickCard.vue'),
      meta: {
        resource: 'Sick',
        action:'read',
        requiresAuth: true,
        pageTitle: 'Ijin Sakit',
        breadcrumb: [
          {
            text: 'Ijin Sakit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sickleaves',
      name: 'sickLeaves',
      component: () => import('@/views/sickLeaves/sickLeaves.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Ijin Sakit',
        breadcrumb: [
          {
            text: 'Ijin Sakit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/updatesickleaves/',
      params: ':_id',
      name: 'updatesickleaves',
      component: () => import('@/views/sickLeaves/UpdateSickLeaves.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Ijin Sakit',
        breadcrumb: [
          {
            text: 'Update Ijin Sakit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/comunication',
      name: 'comunication',
      component: () => import('@/views/comunication/CommCard.vue'),
      meta: {
        resource: 'Komunikasi',
        action:'read',
        requiresAuth: true,
        pageTitle: 'Komunikasi',
        breadcrumb: [
          {
            text: 'Komunikasi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/updatecomm',
      params: ':_id',
      name: 'update-comunication',
      component: () => import('@/views/comunication/UpdateIntComm.vue'),
      meta: {
        requiresAuth: true,
        pageTitle: 'Komunikasi',
        breadcrumb: [
          {
            text: 'Komunikasi',
            active: true,
          },
        ],
      },
    },
    {
      path: '/account-setting',
      name: 'account-setting',
      component: () => import('@/views/account/AccountSetting.vue'),
      meta: {
        resource: 'Account',
        action:'read',
        requiresAuth: true,
        pageTitle: 'Account Setting',
        breadcrumb: [
          {
            text: 'Account Setting',
            active: true,
          },
        ],
      },
    },
    {
      path: '/maternity',
      name: 'maternity',
      component: () => import('@/views/maternity/maternity.vue'),
      meta: {
        requiresAuth: true,
        action: 'read',
        resource: 'Maternity',
        pageTitle: 'Cuti Melahirkan',
        breadcrumb: [
          {
            text: 'Cuti Melahirkan',
            active: true,
          },
        ],
      },
    },
    {
      path: '/',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/notfound',
      name: 'notfound',
      component: () => import('@/views/error/FileNotFound.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '*',
      redirect: 'notfound',
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import('@/views/error/NotAuthorized.vue'),
      meta: {
        layout: 'full',
        action: 'read',
        resource: 'Auth',
      },
    },
    {
      path: '/male-maternity-authorized',
      name: 'not-authorized2',
      component: () => import('@/views/error/NotAuthorizedMaleMaternity.vue'),
    },
    {
      path: '/spo-video',
      name: 'spovideo',
      component: () => import('@/views/spo/spoVideo.vue'),
    },
    {
      path: '*',
      redirect: 'not-authorized',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    //const userData = getUserData()
    // next(getHomeRouteForLoggedInUser(userData ? userData.email : null))
    next({ name : 'home'})
  }

  return next()
})

// router.beforeEach((to, from, next) => {
//   const token = localStorage.getItem('token') != null
//   if (to.name === 'login' && token) {
//     next({ name: 'home' })
//   } else if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!token) {
//       next({
//         path: '/',
//         query: { redirect: to.path },
//       })
//     } else {
//       // next()
//       const maternity = JSON.parse(localStorage.getItem('userData'))
//       console.log(maternity.emp_gender);
//       if (to.matched.some(record => record.meta.maternity)) {
//         if (maternity.emp_gender === to.meta.maternity){
//           next()
//         } else {
//           next('male-maternity-authorized')
//         }
//       } else {
//         next()
//       }

//     }
//   } else {
//     next()
//   }
// })


export default router
